import Grid from './components/Grid/Grid';
import './App.scss';

function App() {
  return (
    <>
    <div className="App">
      < Grid />
    </div>
    </>
  );
}

export default App;
